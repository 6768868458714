import React from 'react';
const infoIcon = require('../icons/info.svg');
const Tag = ({ children }) => {
  return (
    <span className='tag'>
      {children}
      <img className='tag-icon' src={infoIcon} alt='i'/>
    </span>
  );
}
export default Tag;
