import React from 'react';
import { Component } from '@poa/core';
import { observable } from '@poa/state';
import PayerCard from '../components/payer/payer-card';
import PayerList from '../components/payer/payer-list';
import Routeloaded from '../components/route-loading';
import '../styles/components/payer-page.scss'
import { env } from '../env';
import Navbar from '../components/navbar';

class PayerPage extends React.Component {
  state = { meta: { hasError: false, loaded: false}, payer: null }
  store; // injected by Poa
  localState = observable({
    meta : {
      loaded: false,
      hasError: false,  
    },
    payer: null
  })
  async componentDidMount() {
    const payerId = window.location.pathname.split('/')[2];    
    console.log('payerId:', payerId);
    if (payerId) {
      const model = await env.http.get(`payer/${payerId}/`).then(response => response.data);
      console.log('model:', model);
      this.localState.payer = model;
      this.localState.meta.loaded = true;  
    } else {
      this.localState.hasError = true;
    }


    const { startDate, endDate } = this.store.payers.filters;
    this.actions.payersActions.fetchPayers(
      null,
      1,
      null,
      25,
      startDate.toISOString(),
      endDate.toISOString()
    );
  }
  render() {
    const payers = this.store.payers.data || [
      {
        name: "Test payer",
        payer_id: "123456"
      }
    ];
    console.log('this.localState.meta.loaded is:', this.localState.meta.loaded);
    console.log('this.localState.payer', this.localState.payer);
    console.log('this.store', this.store);
    console.log('payers:', payers);
    return (
      <>
        <Routeloaded meta={this.localState.meta}>
          <Navbar/>
          <div className='content-wrapper'>
            <aside>
              <PayerList payers={payers}/>
            </aside>
            {this.localState.payer && <PayerCard payerInfo={this.localState.payer}/>}
            {!this.localState.payer && 'Не найдено!'}
          </div>
        </Routeloaded>
      </>
    );
  }
}

export default Component()(PayerPage);
