import React from "react";

const PayerListItem = ({payer}) => {
    return (
        <span>
            {payer.name || payer.payer_id}
        </span>
    );
};
const PayerList = ({payers}) => {
    
    return (<>
        <ul className='payer-list'>
            {payers && payers.map(p => <li key={'payer-' + p.payer_id}><PayerListItem payer={p}/></li>)}
        </ul>
    </>);
};

export default PayerList;