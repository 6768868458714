import React, { useState } from 'react';
import Tag from '../../tag';
const addressIcon = require('../../../icons/address.svg');
const puzzleIcon = require('../../../icons/puzzle.svg');
const familyIcon = require('../../../icons/family.svg')
const friendsIcon = require('../../../icons/group.svg');
const pawIcon = require('../../../icons/paw.svg');
const giftIcon = require('../../../icons/gift_s.svg');

const PayerCardCommunication = ({ payerInfo, isEditMode }) => {
  const [newPayerInfo, setNewPayerInfo] = useState({ ...payerInfo });
  const PayerSectionBlockWithTags = ({ icon, title, content, isEditMode, fieldName }) => {
    isEditMode = isEditMode === true;
    const fieldChanged = (value) => {
      console.log('Field changed', fieldName, value);
      // how to update the field in the parent component?
      setNewPayerInfo({ ...newPayerInfo, [fieldName]: value });
    };
    return (
      <div className='payer-card-section'>
        <div className='payer-card-section-info-header'>
          <img width='14' height='14' src={icon} />
          <h2>{title}</h2>
        </div>
        {isEditMode && (<textarea value={content} onChange={e => fieldChanged(e.target.value)}/>)}
        {(!isEditMode && content) ? content.split(',').map((tag, index) => {
            return <Tag key={fieldName + '-' + index}>{tag.trim()}</Tag>;
          }
        ) : <Tag>Не указано</Tag>}
      </div>
    );
  };
  isEditMode = isEditMode === true;
  const giftTags = [
    'Дарит подарки: ' + (payerInfo.gift ? 'Да' : 'Нет'),
    payerInfo.gift_type  ? 'Тип подарка: ' + payerInfo.gift_type : false,
    payerInfo.gift_amount ? 'Сумма подарка: ' + payerInfo.gift_amount : false,
    payerInfo.gift_time ? 'Частота подарков: ' + payerInfo.gift_time : false,
    payerInfo.gift_subject ? 'Тема подарка: ' + payerInfo.gift_subject : false
  ].filter(g => g !== false).join(', ');
  return (<div className='payer-card-content payer-card-communication'>
    <PayerSectionBlockWithTags
      isEditMode={isEditMode}
      icon={puzzleIcon} title='Хобби'
      content={payerInfo.hobby}
      fieldName={'hobby'}
    />
    <PayerSectionBlockWithTags
      icon={addressIcon}
      title='Любимые места' content={payerInfo.favorite_places}
      fieldName={'favorite_places'}
    />
    <PayerSectionBlockWithTags
      icon={giftIcon}
      title='Подарки' content={giftTags}
      fieldName={'giftTags'}
    />
    <PayerSectionBlockWithTags
      icon={friendsIcon}
      title='Друзья' content={payerInfo.friends}
      fieldName={'friends'}
    />
    <PayerSectionBlockWithTags
      icon={pawIcon}
      title='Животные' content={payerInfo.animals}
      fieldName={'animals'}
    />
    <PayerSectionBlockWithTags
      icon={familyIcon}
      title='Семья' content={payerInfo.children + ', ' + payerInfo.parents + ', ' + payerInfo.wife}
      fieldName={'family'}
    />
  </div>);
};
export default PayerCardCommunication;
